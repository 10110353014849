import * as React from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {styled} from "@mui/material";
import Loading from "../components/loading";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import {mutate} from "../lib/apollo";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

let uri = (process.env.REACT_APP_API_URL || '').replace("graphql", "rest/iplocation");

export default function IpUpload() {
    const [loading, setLoading] = React.useState(false);
    const [gCount, setCount] = React.useState(0);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setLoading(true);

            const chunkSize = 1024 * 1024 * 3; // size of each chunk (1MB)
            let start = 0;
            let count = 0;
            let time = new Date().getTime();
            let i = 0;
            while (start < file.size) {
                i++;
                let t = await file.slice(start, start + chunkSize).text();
                // find the last occurrence of newline
                const lastNewLine = t.lastIndexOf("\r\n");
                // make t end at lastNewLine
                t = t.substring(0, lastNewLine);
                let ar = t.split("\r\n");
                count += ar.length;
                let input: any = ar.map(x=> {
                    const l = x.split('","');
                    return {
                        f: parseInt(l[0].replace(/["]/g, "")),
                        t: parseInt(l[1].replace(/["]/g, "")),
                        cc: l[2].replace(/["]/g, ""),
                        cn: l[3].replace(/["]/g, ""),
                        r: l[4].replace(/["]/g, ""),
                        c: l[5].replace(/["]/g, ""),
                        la: parseFloat(l[6].replace(/["]/g, "")),
                        lo: parseFloat(l[7].replace(/["]/g, "")),
                        p: l[8].replace(/["]/g, ""),
                        d: l[9].replace(/["]/g, ""),
                        mc: l[10].replace(/["]/g, ""),
                        mn: l[11].replace(/["]/g, ""),
                        mb: l[12].replace(/["]/g, ""),
                        u: l[13].replace(/["]/g, ""),
                    }
                })

                await upload(input, start === 0, false);

                let elapsed = new Date().getTime() - time;
                // convert elapsed to seconds
                elapsed = elapsed / 1000;
                console.log(i, ar.length, count, elapsed, "s");
                setCount(count);

                // if (i % 10 === 0) {
                //     console.log("Sleeping for 10 seconds");
                //     await sleep(10);
                // }


                input = [];
                ar = [];
                t = "";

                start += lastNewLine + 2;
            }

            if (count > 10000000) {
                await upload([], false, true);
            }

            setLoading(false);
            // clear file
            event.target.value = "";

        }

    }

    function sleep(s: number) {
        return new Promise(resolve => setTimeout(resolve, s * 1000));
    }

    const upload = async (input: any[], isFirst: boolean, isLast: boolean) => {
        const response = await fetch(uri, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({input, isFirst, isLast})
        })
        // const q = gql`
        //     mutation m($input: [IPLocationWrite!]!, $isFirst: Boolean!, $isLast: Boolean!) {
        //         locationIPUpload(input: $input, isFirst: $isFirst, isLast: $isLast)
        //     }
        // `;
        // let r = await mutate(q, {input, isFirst, isLast});
        // if (r && r.locationIPUpload && isLast) {
        //     toast.success("File uploaded");
        // }
    }


    return (
        <>
            <h2>IP Location Upload</h2>

            <p>Upload IP-COUNTRY-REGION-CITY-LATITUDE-LONGITUDE-ISP-DOMAIN-MOBILE-USAGETYPE.CSV from IP2Location</p>

            <p>Note: Check the console log, this will take around 30 minutes</p>

            <p>Count: {gCount.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</p>

            {loading ? <Loading/> :
                <>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
            >
                Upload file
                <VisuallyHiddenInput type="file" accept={".csv"} onChange={handleChange} />
            </Button>
                </>
            }
        </>
    )
}
