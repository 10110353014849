import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch} from "@mui/material";
import TextField from "@mui/material/TextField";
import SingleSelect from "../../components/SingleSelect";
import {getBillingGroup, getDupeGroup} from "../schools/schoolsService";
import LoadingDialog from "../../components/loadingDialog";
import Button from "@mui/material/Button";
import {gql} from "@apollo/client";
import {mutate} from "../../lib/apollo";
import {toast} from "react-toastify";

export default function ClickWallSchoolUpsert (params: {school: any, setSchool: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [name, setName] = React.useState('' as string);
    const [location, setLocation] = React.useState('' as string);
    const [logoUrl, setLogoUrl] = React.useState('' as string);
    const [isOnline, setIsOnline] = React.useState(true);
    const [isProfit, setIsProfit] = React.useState(false);
    const [isPublic, setIsPublic] = React.useState(false);
    const [isActive, setIsActive] = React.useState(true);
    const [order, setOrder] = React.useState(0 as number);
    const [weight, setWeight] = React.useState(0 as number);


    React.useEffect(() => {
        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (params.school) {
            if (params.school.name) {
                setName(params.school.name || '');
                setLocation(params.school.location || '');
                setLogoUrl(params.school.logo_url || '');
                setIsOnline(params.school.is_online || false);
                setIsProfit(params.school.is_profit || false);
                setIsPublic(params.school.is_public || false);
                setIsActive(params.school.is_active || false);
                setOrder(params.school.school_order || 0)
                setWeight(params.school.gw_weight || 0);
            } else {
                setName('');
                setLocation('');
                setLogoUrl('');
                setIsOnline(true);
                setIsProfit(false);
                setIsPublic(false);
                setIsActive(true);
                setOrder(0);
                setWeight(0);
            }
        }
    }, [params.school]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setSchool(null);
    };


    if (!params.school) return <> </>;

    const isEdit = !!params.school.id;

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        const input = {
            name,
            location,
            logo_url: logoUrl,
            is_online: isOnline,
            is_profit: isProfit,
            is_public: isPublic,
            is_active: isActive,
            school_order: order,
            gw_weight: weight
        };


        if (isEdit) {
            q = gql`
                mutation m($id: Int!, $school: ClickWallSchoolWrite!) {
                    clickWallSchoolUpdate(id: $id, school: $school)
                }
            `;

            variables = {
                id: params.school.id,
                school: input
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.clickWallSchoolUpdate;
        } else {
            q = gql`
                mutation m($school: ClickWallSchoolWrite!) {
                    clickWallSchoolCreate(school: $school)
                }
            `;

            variables = {
                school: input
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.clickWallSchoolCreate;
        }

            if (good) {
                toast.success(isEdit ? 'School updated successfully' : 'School created successfully');
                setLoading(false);
                handleClose();
                params.reload();
            } else {
                setLoading(false);
            }
        }

    return (
        <>
            <Dialog
                open={!!params.school}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    {isEdit ? `Update: ${params.school.name}` : 'Create School'}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="location"
                                label="Location"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="logoUrl"
                                label="Logo Url"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={logoUrl}
                                onChange={(e) => setLogoUrl(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="schoolOrder"
                                label="School Order"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={order}
                                onChange={(e) => setOrder(parseInt(e.target.value))}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="gw_weight"
                                label="GW Weight"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={weight}
                                onChange={(e) => setWeight(parseInt(e.target.value))}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel control={<Switch checked={isOnline} onChange={(event)=> setIsOnline(event.target.checked)} />} label={isOnline ? 'Online' : 'Campus'} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel control={<Switch checked={isProfit} onChange={(event)=> setIsProfit(event.target.checked)} />} label={isProfit ? 'For Profit' : 'Non-Profit'} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel control={<Switch checked={isPublic} onChange={(event)=> setIsPublic(event.target.checked)} />} label={isPublic ? 'Public' : 'Private'} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel control={<Switch checked={isActive} onChange={(event)=> setIsActive(event.target.checked)} />} label={isActive ? 'Active' : 'Inactive'} />
                        </Grid>
                    </Grid>
                </DialogContent>
                {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            {isEdit ? `Update` : 'Create'}
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}
